import Link from 'next/link';

export default function NotFound() {
  return (
    <>
      <main className="h-screen min-h-full bg-not_found_page bg-cover bg-top sm:bg-top">
        <div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-base font-semibold text-black text-opacity-50">404</p>
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Ops! Coś poszło nie tak.
          </h1>
          <p className="mt-2 text-lg font-medium text-teal-50 text-opacity-50">
            Wygląda na to, że nie ma takiej strony.{' '}
            <Link shallow href="/contact" className="bold underline">
              Daj nam znać
            </Link>{' '}
            o tym błędzie lub
          </p>
          <div className="mt-6">
            <Link
              shallow
              href="/"
              className="inline-flex items-center rounded-md border border-transparent bg-white bg-opacity-75 px-4 py-2 text-sm font-medium text-black text-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
            >
              Wróć na stronę główną
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}

NotFound.layout = 'None';
